.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  border-radius: 15px;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00b56a;
  cursor: pointer;
}
