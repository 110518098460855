.FirstColumn,
.SecondColumn {
  width: 100%;
  height: 50vh !important;
  padding: 25px;
}
.LandingImage {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.LandingImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.SecondColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .FirstColumn,
  .SecondColumn {
    min-height: 50vh !important;
  }
}
