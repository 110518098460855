.FirstColumn,
.SecondColumn {
  width: 100%;
  height: 50vh !important;
  padding: 25px;
}

.LandingImage {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.LandingImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.SecondColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.otpWrapper {
  width: 100%;
}

.otpWrapper input {
  width: 3em !important;
  font-size: 18px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.text a{
  color: #00b56a;
}
@media only screen and (min-width: 768px) {

  .FirstColumn,
  .SecondColumn {
    min-height: 50vh !important;
  }
}