.aboutfooter {
  height: 55px;
  width: 100%;
  background: #045a14;
  position: fixed;
  bottom: 0;
  color: #ffffff;
  padding-top: 10px;
}

.aboutfooter a {
  color: #ffffff;
  font-size: 10px;
}

.bookingsHeaderIcons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.columns {
  position: relative;
}

.FirstColumn {
  padding: 20px;
  height: 15vh;
  /* background-color: #00cb78; */
  background: url("../images/homeBackground.png") !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.aboutusdetail {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.aboutusdetail h2 {
  font-size: 20px;
  color: #2C2C2C;
  font-weight: 700;
}

.aboutusdetail p {
  margin-top: 10px;
}

.aboutusimg img {
  height: 225px;
  padding-left: 50px;
  padding-top: 20px;
}
.pol{
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}
.pol a {
  color: #ffffff;
  /* margin-right: 30px; */
}
.powerby{
  text-align: center;
  font-size: 15px;
}
.powerby a{
  font-weight: 700;
}
.powerby a:hover{
  color: #ffffff;
}
.aboutusimg {
  background: url('../images/Group\ 3.png') no-repeat;
  height: 50vh;
  background-position: top 35px center;
}

.aboutuslinks {
  display: grid;
  float: right;
  text-align: end;
  margin-right: 20px;
}

.aboutuslinks a {
  color: #2C2C2C;
  font-weight: 700;
}

.headerlogo img {
  height: 60px;
  margin-left: 40px;
}