.FirstColumn,
.CenterColumn,
.SecondColumn {
  width: 100%;
}
.columns {
  position: relative;
}
.FirstColumn {
  padding: 20px;
  height: 15vh;
  /* background-color: #00cb78; */
  background: url("../images/homeBackground.png") !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.SecondColumn {
  padding: 20px;
  height: auto;
}
.bookingsHeaderIcons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CenterColumn {
  height: 10vh;
  background-color: #bae5d3;
  margin: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}
.dateWrapper {
  width: 30%;
}
.startTimeWrapper,
.endTimeWrapper {
  width: 33%;
}
.inValidSlot {
  cursor: not-allowed;
}
.isSelected {
  background-color: #00cb78;
  color: white;
}
@media only screen and (min-width: 768px) {
  .FirstColumn,
  .CenterColumn,
  .SecondColumn {
    width: 800px;
  }
  .FirstColumn,
  .CenterColumn,
  .SecondColumn {
    margin-top: 0% !important;
  }
}
abbr{
  text-decoration: none !important;
}
.headerlogo img{
  height: 60px;
    margin-left: 40px;
}