.FirstColumn,
.SecondColumn {
  width: 100%;
}
.FirstColumn {
  padding: 20px;
  height: auto;
  /* background-color: #00cb78; */
  background: url("../images/homeBackground.png") !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.SecondColumn {
  padding: 20px;
  height: auto;
}
.CenterColumn {
  height: 10vh;
  background-color: #00cb78;
  margin: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  box-shadow: 0;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .cartContainer {
    flex-direction: column !important;
  }
  .FirstColumn,
  .CenterColumn,
  .SecondColumn {
    width: 800px;
  }
  .FirstColumn,
  .CenterColumn,
  .SecondColumn {
    margin-top: 0% !important;
  }
}

.headerlogo img{
  height: 60px;
    margin-left: 40px;
}