.FirstColumn,
.SecondColumn {
  width: 100%;
}
.FirstColumn {
  padding: 20px;
  height: 10vh;
  /* background-color: #00cb78; */
  background: url("../images/homeBackground.png") !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.SecondColumn {
  padding: 20px;
  height: auto;
}
.bookingsHeaderIcons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upcomingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upcomingHeader p {
  margin: 0 !important;
}
.customUI {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #045a14;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.customUI > h1 {
  margin-top: 0;
}

.customUI > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 15px;
}
.boxStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #bae5d3;
}
@media only screen and (min-width: 768px) {
  .FirstColumn,
  .SecondColumn {
    width: 800px;
  }
  .FirstColumn,
  .SecondColumn {
    margin-top: 0% !important;
  }
}
.headerlogo img{
  height: 60px;
    margin-left: 40px;
}