.FirstColumn,
.SecondColumn {
  width: 100%;
}
.FirstColumn {
  padding: 20px;
  height: 25vh;
  /* background-color: #00cb78; */
  background: url("../images/homeBackground.png") !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.SecondColumn {
  padding: 20px;
  height: 65vh;
}
.HeaderNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: -45px;
}
.timeWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -45px;
}

.timeWrapper .field {
  width: 45% !important;
  margin: 0 !important;
}
@media only screen and (min-width: 768px) {
  .FirstColumn,
  .SecondColumn {
    width: 800px !important;
  }
  .FirstColumn {
    margin: 0 !important;
  }
}
.headerlogo img{
  height: 60px;
    margin-left: 40px;
}
.quote{
  color: #00b56a;
  font-weight: 700;
}