@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.HomeBackGround {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/homeBackground.png") !important;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.column {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.HomeHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CarouselWrapper {
  width: 100%;
  height: 40vh !important;
}
.slogan{
  margin-top: 10px !important;
  max-width: 400px;
  margin: auto;
}
.slogan h2{
  color: #00D1F6;
  font-family: 'Anton', sans-serif;
  font-size: 27px;
}
.slogan h3{
  color: #ffffff ;
  font-size: 10px;
  text-align: center;
}